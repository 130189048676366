import { forwardRef } from 'react'
import { Button as AntdButton, ButtonProps } from 'antd'
import clsx from 'clsx'
import styles from './button.module.css'

export const SecondaryButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { className } = props
  return (
    <AntdButton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      size="large"
      ref={ref}
      className={clsx(styles.baseButton, className)}
    />
  )
})

SecondaryButton.displayName = 'SecondaryButton'

export const PrimaryButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { className } = props
  return (
    <AntdButton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      size="large"
      ref={ref}
      className={clsx(styles.primaryButton, className)}
    />
  )
})

PrimaryButton.displayName = 'PrimaryButton'

export const SecondaryButtonV2 = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { className } = props
  return (
    <AntdButton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      size="large"
      ref={ref}
      className={clsx(styles.baseButtonV2, className)}
    />
  )
})

SecondaryButtonV2.displayName = 'SecondaryButtonV2'

export const PrimaryButtonV2 = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { className } = props
  return (
    <AntdButton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      size="large"
      ref={ref}
      className={clsx(styles.primaryButtonV2, className)}
    />
  )
})

PrimaryButtonV2.displayName = 'PrimaryButtonV2'
