export function installRecaptchaV3(siteKey: string | null | undefined): void {
  if (!siteKey || isElementWithIdExist(siteKey)) {
    return
  }

  const script = document.createElement('script')
  script.id = siteKey
  script.src = `https://www.google.com/recaptcha/enterprise.js?render=${siteKey}`
  script.async = true
  script.defer = true
  document.body.append(script)
}

function isElementWithIdExist(elementId: string): boolean {
  return !!document.querySelector(`[id='${elementId}']`)
}
