import { QueryCache, QueryClient } from 'react-query'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // we shall enable this on a case-by-case basis
      retry: false,
      // we shall enable this on a case-by-case basis
      refetchOnWindowFocus: false,
      // we shall enable this on a case-by-case basis
      cacheTime: 0,
    },
  },
  queryCache: new QueryCache(),
})
