import Storage from 'local-storage-fallback'

function fallbackStorage() {
  return Storage
}

function createStorage() {
  let storage

  if (typeof window !== 'undefined') {
    const KEY = `local_storage_supported_${Math.random()}`

    try {
      window.localStorage.setItem(KEY, 'true')

      if (window.localStorage.getItem(KEY) !== 'true') {
        throw new Error('Unable to use `window.localStorage`.')
      }

      window.localStorage.removeItem(KEY)
      storage = window.localStorage
    } catch {
      // Occurs when the browser(i.e. Safari) is in private mode.
      storage = fallbackStorage()
    }
  } else {
    // Occurs when it's a Node environment.
    storage = fallbackStorage()
  }

  return storage
}

export const AppStorage = createStorage()
