import { VFC } from 'react'
import { ReactComponent as Circle } from './circle.svg'

export const Loading: VFC = () => {
  return (
    <div
      data-testid="loading"
      className="flex flex-row items-center justify-center w-full py-16 text-base leading-6 cursor-wait text-primary"
    >
      <Circle className="w-5 h-5 mr-3 -ml-1 animate-spin" />
      <span>Loading...</span>
    </div>
  )
}
