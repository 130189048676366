import LanguageDetector from 'i18next-browser-languagedetector'
import { countryLngMap, SupportedCountryCode } from '@/constants/country'
import { SupportedLanguages } from '@/constants/locale'

const detectLngByCountryCodeName = 'byCountryCode'
export const lookupLngLocalStorageKey = 'i18nextLng'

export const detectByCountryCode = {
  name: detectLngByCountryCodeName,
  lookup(): SupportedLanguages | '' | undefined {
    const { location } = window
    const params = new URLSearchParams(location.search)
    const countryCode = params.get('country')

    if (countryCode) {
      return countryLngMap.get(countryCode.toLocaleUpperCase().toString() as SupportedCountryCode)
    }
    return ''
  },
}

export const detectionOptions = {
  order: ['localStorage', detectLngByCountryCodeName, 'navigator'],
  lookupLocalStorage: lookupLngLocalStorageKey,
  caches: [],
  excludeCacheFor: ['cimode'],
}

export const languageDetector = new LanguageDetector()
