import * as Sentry from '@sentry/react'

export function initSentry(): void {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: window.Grab.config.sentryDsn,
      environment: window.Grab.config.appEnv,
      release: process.env.APP_VERSION,
      /**
       * We don't need tracing now as it introduce a lot of bytes (10KB after gzip!) into our bundle.
       * If there is a benefit of using it, install `@sentry/tracing` module,
       * and add the following config:
       * {
       *   integrations: [new Integrations.BrowserTracing()],
       *   tracesSampleRate: 0.5,
       * }
       * @see https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/
       */
    })
  }
}
