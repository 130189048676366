import { VFC } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import {
  SignUpPage,
  NotFoundPage,
  VerifyUserPage,
  PostRegistrationPage,
  GotoAppPage,
  NewSignUpPage,
  PaxLandingPage,
} from '@/pages'

const PublicRoute = Route

export const AppRoutes: VFC = () => {
  return (
    <QueryParamProvider ReactRouterRoute={Route}>
      <div className="flex w-full min-h-screen bg-very-light">
        <Switch>
          <PublicRoute path="/" exact component={SignUpPage} />
          <PublicRoute path="/verify-user" exact component={VerifyUserPage} />
          <PublicRoute path="/post-registration" exact component={PostRegistrationPage} />
          <PublicRoute path="/goto-app" exact component={GotoAppPage} />
          <PublicRoute path="/404" exact component={NotFoundPage} />
          <PublicRoute path="/bp" exact component={PaxLandingPage} />
          <PublicRoute path="/businesscentre" exact component={PaxLandingPage} />
          <PublicRoute path="/new" exact component={NewSignUpPage} />
          <Redirect from="*" to="/404" />
        </Switch>
      </div>
    </QueryParamProvider>
  )
}
