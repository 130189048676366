import { SupportedLanguages } from './locale'

export const countryCodes = ['SG', 'MY', 'PH', 'TH', 'VN', 'ID', 'MM', 'KH'] as const
export enum SupportedCountryName {
  Singapore = 'Singapore',
  Malaysia = 'Malaysia',
  Philippines = 'Philippines',
  Thailand = 'Thailand',
  Vietnam = 'Vietnam',
  Indonesia = 'Indonesia',
  Myanmar = 'Myanmar',
  Cambodia = 'Cambodia',
}

export type SupportedCountryCode = typeof countryCodes[number]

export type Country = {
  labelKey: string
  value: SupportedCountryCode
}

export const countries: Country[] = [
  {
    labelKey: 'singapore',
    value: 'SG',
  },
  {
    labelKey: 'malaysia',
    value: 'MY',
  },
  {
    labelKey: 'philippines',
    value: 'PH',
  },
  {
    labelKey: 'thailand',
    value: 'TH',
  },
  {
    labelKey: 'vietnam',
    value: 'VN',
  },
  {
    labelKey: 'indonesia',
    value: 'ID',
  },
  {
    labelKey: 'myanmar',
    value: 'MM',
  },
  {
    labelKey: 'cambodia',
    value: 'KH',
  },
]

// change lng when we support another lng
export const countryLngMap: Map<SupportedCountryCode, SupportedLanguages> = new Map([
  ['SG', 'en'],
  ['MY', 'en'],
  ['PH', 'en'],
  ['TH', 'th'],
  ['VN', 'vi'],
  ['ID', 'id'],
  ['MM', 'en'],
  ['KH', 'en'],
])

// get country code from country name
export const countryCodeNameMap: Map<SupportedCountryCode, SupportedCountryName> = new Map([
  ['SG', SupportedCountryName.Singapore],
  ['MY', SupportedCountryName.Malaysia],
  ['PH', SupportedCountryName.Philippines],
  ['TH', SupportedCountryName.Thailand],
  ['VN', SupportedCountryName.Vietnam],
  ['ID', SupportedCountryName.Indonesia],
  ['MM', SupportedCountryName.Myanmar],
  ['KH', SupportedCountryName.Cambodia],
])
