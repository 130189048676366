import { ComponentType, FC } from 'react'
import loadable, { DefaultComponent } from '@loadable/component'
import { UncaughtErrorBoundary } from '@/components/error-boundary'
import { Loading } from '@/libs/async-component/loading'

const withLoadingFallback = {
  fallback: <Loading />,
}

type LoadableFn<T> = () => Promise<DefaultComponent<T>>

export function makeAsyncComponent<Props>(loadableFn: LoadableFn<Props>): ComponentType<Props> {
  const LoadableDefaultImportComponent = loadable(loadableFn, withLoadingFallback)
  const AsyncComponent: FC<Props> = (props) => {
    return (
      <UncaughtErrorBoundary>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <LoadableDefaultImportComponent {...props} />
      </UncaughtErrorBoundary>
    )
  }

  return AsyncComponent
}
