/**
 * CSS import order are important,this css import block
 * must be moved to the top to ensure it comes before the CSS Module ones
 */
// eslint-disable-next-line no-restricted-imports
import 'antd/dist/antd.variable.min.css'
import './assets/fonts/interlooped.css'
import './assets/fonts/grabcomm.css'
import './grab.vars.css'
import './antd.vars.css'
// Start importing the other modules from here
import { StrictMode, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { queryClient } from '@/libs/react-query-client'
import { initScribe } from '@/libs/scribe'
import { reportWebVitals } from './report-web-vitals'
import { AppRoutes } from './routes'
import { installRecaptchaV3 } from './libs/recaptchav3'
import { initSentry } from './libs/sentry'
import { initGrabConfig } from './libs/grab-config'
import { setupAntDesign } from './libs/ant-design'
import { getBasename } from './utils/basename'
// One time setup
import './libs/setup-i18next'
// Allow Taiwind Classes override CSS Modules (components' CSS) for className composition
import './index.css'

async function main() {
  await initGrabConfig()
  setupAntDesign()
  installRecaptchaV3(window.Grab.config.reCAPTCHASiteKey)
  initSentry()
  initScribe()
  const basename = getBasename()

  ReactDOM.render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        {/* this Suspense wrapper is used for i18next language fallback */}
        <Suspense fallback="">
          <BrowserRouter basename={basename}>
            <AppRoutes />
          </BrowserRouter>
        </Suspense>
      </QueryClientProvider>
    </StrictMode>,
    document.querySelector('#root')
  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
}

main()
