import { ReactNode, FC } from 'react'
import {
  ErrorBoundary,
  FallbackProps as ReactErrorBoundaryFallBackProps,
} from 'react-error-boundary'
import { PrimaryButton } from '@/components/antd/button'

interface Props {
  children: ReactNode
  onReset?: () => void
  onError?: (
    error: Error,
    info: {
      componentStack: string
    }
  ) => void
}

interface FallbackProps extends ReactErrorBoundaryFallBackProps {
  isRetryable?: boolean
}

const ErrorDetails: FC<FallbackProps> = ({ error, resetErrorBoundary, isRetryable }) => {
  return (
    <div data-testid="fallback-component">
      <p data-testid="fallback-component-title">Oops - Something went wrong</p>
      <p data-testid="fallback-component-message">Message : {error.toString()}</p>
      <details data-testid="fallback-component-stacktrace">
        <summary>Stacktrace</summary>
        <p>{error.stack}</p>
      </details>
      {isRetryable && (
        <PrimaryButton data-testid="fallback-component-retry-btn" onClick={resetErrorBoundary}>
          Retry
        </PrimaryButton>
      )}
    </div>
  )
}

const UncaughtErrorBoundaryFallback = ({
  error,
  resetErrorBoundary,
}: ReactErrorBoundaryFallBackProps) => {
  return <ErrorDetails error={error} resetErrorBoundary={resetErrorBoundary} isRetryable={false} />
}

export const UncaughtErrorBoundary: FC<Props> = ({ children, onError }) => {
  return (
    <ErrorBoundary onError={onError} FallbackComponent={UncaughtErrorBoundaryFallback}>
      {children}
    </ErrorBoundary>
  )
}

const RetryableErrorBoundaryFallback = ({
  error,
  resetErrorBoundary,
}: ReactErrorBoundaryFallBackProps) => {
  return <ErrorDetails error={error} resetErrorBoundary={resetErrorBoundary} isRetryable />
}

export const RetryableErrorBoundary: FC<Props> = ({ children, onReset, onError }) => {
  return (
    <ErrorBoundary
      onReset={onReset}
      onError={onError}
      FallbackComponent={RetryableErrorBoundaryFallback}
    >
      {children}
    </ErrorBoundary>
  )
}
