const grabHostNames = new Set(['enterprise.stg-myteksi.com', 'grab.com', 'business.grab.com'])

// more detail: https://jira.grab.com/browse/GFB-12091
export const getBasename = (): string => {
  if (process.env.NODE_ENV === 'production' && grabHostNames.has(window.location.host)) {
    return '/signup'
  }

  return ''
}
