import i18next from 'i18next'
import resourcesToBackend from 'i18next-resources-to-backend'
import { initReactI18next } from 'react-i18next'
import { languages, SupportedLanguages } from '@/constants/locale'
import { detectByCountryCode, detectionOptions, languageDetector } from './language-detector'

const DEFAULT_LANGUAGE: SupportedLanguages = 'en'

languageDetector.addDetector(detectByCountryCode)
i18next
  .use(languageDetector)
  .use(initReactI18next)
  .use(
    resourcesToBackend((language, namespace, callback) => {
      import(`@/locales/${language}/${namespace}.json`)
        .then((resources) => {
          callback(undefined, resources)
        })
        .catch((error) => {
          /// typescript bug?
          // eslint-disable-next-line unicorn/no-useless-undefined
          callback(error, undefined)
        })
    })
  )
  .init({
    detection: detectionOptions,
    debug: process.env.NODE_ENV === 'development',
    preload: false,
    load: 'languageOnly',
    ns: [],
    supportedLngs: languages,
    fallbackLng: DEFAULT_LANGUAGE,
    partialBundledLanguages: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })
