import { makeAsyncComponent } from '@/libs/async-component'

/**
 * Please wrap all of the available pages in `makeAsyncComponent`
 * Note that the `page component` must be a default export, and you can use
 * `// eslint-disable-next-line import/no-default-export`
 * to ignore the `no-default-export` rule for `page component`
 */

export const SignUpPage = makeAsyncComponent(() => {
  return import('./signup')
})

export const NotFoundPage = makeAsyncComponent(() => {
  return import('./not-found')
})

export const VerifyUserPage = makeAsyncComponent(() => {
  return import('./verify-users')
})

export const PostRegistrationPage = makeAsyncComponent(() => {
  return import('./post-registration')
})

export const GotoAppPage = makeAsyncComponent(() => {
  return import('./goto-app')
})

export const NewSignUpPage = makeAsyncComponent(() => {
  return import('./signup/new')
})

export const PaxLandingPage = makeAsyncComponent(() => {
  return import('./pax-landing')
})
