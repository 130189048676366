import { AppStorage } from './app-storage'

export async function initGrabConfig(): Promise<void> {
  // this file is especially kept as-is and exported as a url, not a module.
  // so using `require()` is better than `import()`
  // eslint-disable-next-line unicorn/prefer-module
  const configResponse = await fetch(require('config.json'))
  const config = await configResponse.json()

  window.Grab = {
    config,
    storage: AppStorage,
  }
}
